<template>
  <div>
    <b-modal
      id="parcelasList"
      ref="parcelasList"
      button-size="sm"
      no-close-on-backdrop
      ok-only
      :no-stacking="stacking"
      ok-title="Ok"
      size="lg"
      :no-enforce-focus="true"
    >
      <div slot="modal-title">
        <h4>{{ 'Parcelas - ' + parcelamento.razaoSocial }}</h4>
        <fieldset class="font-weight-bold text-muted small">
          Nesta tela estão as ultimas parcelas lidas pelo Monitor, você pode mandar as guias por email ou baixar
        </fieldset>
      </div>
      <h5
        v-if="showParc"
        style=""
      >
        Histórico <span style="float: right;">{{ parcelamento.totalParcelas }}</span>
      </h5>

      <menu-lateral
        :pendencias="parcelamento.tributos"
        @selecionado="selecionarTributo"
      />

      <visualizacao
        v-model="parcelasSelecionadas"
        :grupo-tributo="tributoSelecionado"
        :tipo-parcelamento="tipoParcelamento"
        :razao-social="parcelamento.razaoSocial"
        @envioEmail="openEnvioEmail($event)"
        @mostrarGuia="visualizarGuia($event)"
      >
        <template
          slot="acao-observacao"
          slot-scope="{ props}"
        >
          <b-btn
            v-b-tooltip.hover
            title="Observação"
            variant="none"
            class="btn-none rounded-pill m-0 icone-acao p-0"
            @click="$emit('openObservacoes', props)"
          >
            <font-awesome-icon
              icon="fa-regular fa-message"
              :class="{ 'text-warning': props.temObservacoesNaoLidas, 'text-primary': !props.temObservacoesNaoLidas }"
            />
          </b-btn>
        </template>
      </visualizacao>
    </b-modal>
  </div>
</template>

<style lang="scss">
.modal:nth-of-type(even) {
    z-index: 1052 !important;
}
.modal-backdrop.show:nth-of-type(even) {
    z-index: 1051 !important;
}
</style>

<script>
export default {
  name: 'ParcelaView',
  components: {
    MenuLateral: () => import('./components/menu-lateral/MenuLateral.vue'),
    Visualizacao: () => import('./components/visualizacao/Visualizacao.vue'),
  },
  props: {
    parcelamento: {
      type: Object,
      required: true,
    },
    requestParcelas: {
      type: Function,
      required: true,
    },
    tipoParcelamento: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      stacking: true,
      mostrarArquivo: false,
      urlArquivo: '',
      parcelas: [],
      tributoSelecionado: '',
      parcelasSelecionadas: [],
      colunas: [
        {
          key: 'competencia', label: 'Competência', tdClass: 'col-2',
        },
        {
          key: 'numeroParcela', label: 'Parcela', tdClass: 'col-2',
        },
        {
          key: 'status', label: 'Status',
        },
        {
          key: 'valor', label: 'Valor (R$)',
        }],
      showParc: false,
      showParcEntrada: false,
    }
  },
  methods: {
    carregarParcelas() {
      if (this.requestParcelas) {
        this.requestParcelas(this.parcelamento.id)
          .then(payload => {
            this.parcelas = payload.data
            this.parcelasSelecionadas = this.parcelas.filter(find => find.tributo.toLowerCase() === this.tributoSelecionado.toLowerCase())
          })
          .catch(err => {
            throw err
          })
      }
    },
    selecionarTributo(tributo) {
      this.tributoSelecionado = tributo
      this.parcelasSelecionadas = this.parcelas.filter(find => find.tributo.toLowerCase() === tributo.toLowerCase())
    },
    show() {
      this.carregarParcelas()
      this.$refs.parcelasList.show()
    },
    openEnvioEmail(id) {
      this.$emit('envioEmail', { id: this.parcelamento.id, parcelaId: id })
    },
    visualizarGuia(id) {
      this.$emit('mostrarGuia', { id: this.parcelamento.id, parcelaId: id })
    },
    hide() {
      this.$refs.parcelasList.hide()
    },
  },
}

</script>
